import Api from "@/services/api";
import {removeEmptyParams} from "@utils";

export default {
  findAll(filter) {
    return Api().get("/rolepermissions", {params: removeEmptyParams(filter)}).then(res => {
        res.data.forEach(e => this.formatRes(e));
        return res.data;
      }
    );
  },
  findAllByCurrentUser() {
    return Api().get("/rolepermissions/currentuser").then(res => res.data);
  },
  findById(id) {
    return Api().get("/rolepermission/" + id).then(res => res.data);
  },
  authorizeAccessByPermission(permission) {
    return Api().get("/rolepermission/authorization/" + permission).then(res => res.data);
  },
  authorizeAccessByPermissions(permissions, logic) { // logic = 'and' || 'or'
    return Api().get("/rolepermission/authorization?logic=" + logic + '&permissions=' + permissions.map((n) => `${n}`).join(',')).then(res => res.data);
  },
  create(rolePermission) {
    return Api().post("/rolepermission", rolePermission).then(res => res.data);
  },
  update(rolePermission) {
    return Api().put("/rolepermission/" + rolePermission.id, rolePermission).then(res => res.data);
  },
  delete(rolePermission) {
    return Api().delete("/rolepermission/" + rolePermission.id).then(() => rolePermission);
  },
  formatRes(e) {
    return e;
  }
}