import Api from "@/services/api";
import {removeEmptyParams} from "@utils";

export default {
    findAll() {
        return Api().get("/affectations").then(res => {
            res.data.forEach(e => this.formatRes(e));
            return res.data;
        });
    },
    findById(id) {
        return Api().get("/affectation/" + id).then(res => res.data);
    },
    findAllByUserId(userId) {
        return Api().get("/affectations/" + userId).then(res => res.data);
    },
    formatRes(e) {
        return e;
    }
}